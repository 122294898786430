import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_llm_arena.svg';
import { CustomDropdown } from './CustomDropdown';
import { useMatch } from 'react-router-dom';
import { useMediaQuery } from '@uidotdev/usehooks';


export const Menu = ({ handleChange, languages, categories }) => {

  const match = useMatch('/play');

  const isLargeDevice = useMediaQuery("only screen and (min-width : 769px)");

  const navigate = useNavigate();
  const handleMenuClick = (link) => {
    navigate(link)
  }
  return (
    <div className="top-0 flex items-center justify-between w-full py-2 mx-auto flex-nowrap">
      <img src={logo} onClick={() => handleMenuClick('/home')} className='w-[8rem] h-[7rem] mb-3 cursor-pointer' alt="logo_llm_arena" />

      {match && categories && languages && isLargeDevice && <div className='w-full p-0 mb-3'> <CustomDropdown handleChange={handleChange} languages={languages} categories={categories} /></div>}


      <div className="frame_2403 flex font-dmMonoMedium  items-center gap-2.5 self-stretch mb-3">
        <svg onClick={() => handleMenuClick('/about')} width={56} height={57} className='cursor-pointer' viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M50.625 8.69644C50.625 7.31573 49.5057 6.19644 48.125 6.19644H7.875C6.49429 6.19644 5.375 7.31573 5.375 8.69644V48.9464C5.375 50.3272 6.49429 51.4464 7.875 51.4464H48.125C49.5057 51.4464 50.625 50.3272 50.625 48.9464V8.69644Z" fill="#793D27" stroke="#4C2215" strokeWidth={2} strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M13.125 6.94644C12.1585 6.94644 11.375 7.72994 11.375 8.69644V48.9464C11.375 49.9129 12.1585 50.6964 13.125 50.6964H42.875C43.8415 50.6964 44.625 49.9129 44.625 48.9464V8.69644C44.625 7.72994 43.8415 6.94644 42.875 6.94644H13.125ZM29.75 23.5714C29.75 22.6623 29.2878 21.8611 28.5856 21.39C28.2263 21.7105 28 22.1771 28 22.6964C28 23.1797 27.6082 23.5714 27.125 23.5714H18.125C17.2966 23.5714 16.625 22.8999 16.625 22.0714C16.625 17.1009 20.6544 13.0714 25.625 13.0714H30.625C36.424 13.0714 41.125 17.7725 41.125 23.5714C41.125 29.3704 36.424 34.0714 30.625 34.0714C30.625 35.5212 29.4497 36.6964 28 36.6964H21.875C20.4253 36.6964 19.25 35.5212 19.25 34.0714V31.4464C19.25 28.5469 21.6005 26.1964 24.5 26.1964H27.125C28.5747 26.1964 29.75 25.0212 29.75 23.5714ZM28.276 39.3296C27.9102 38.7408 27.2988 38.4464 26.6875 38.4464H23.1875C22.5762 38.4464 21.9648 38.7408 21.599 39.3296L21.2677 39.8627C21.1161 40.1067 20.9103 40.3125 20.6663 40.4641L20.1331 40.7954C18.9556 41.5271 18.9556 43.2407 20.1331 43.9725L20.6663 44.3038C20.9103 44.4554 21.1161 44.6612 21.2677 44.9052L21.599 45.4383C21.9648 46.0271 22.5762 46.3214 23.1875 46.3214H26.6875C27.2988 46.3214 27.9102 46.0271 28.276 45.4383L28.6073 44.9052C28.7589 44.6612 28.9647 44.4554 29.2087 44.3038L29.7419 43.9725C30.9194 43.2407 30.9194 41.5271 29.7419 40.7954L29.2087 40.4641C28.9647 40.3125 28.7589 40.1067 28.6073 39.8627L28.276 39.3296Z" fill="#ED4652" />
          <path d="M6.125 8.69644C6.125 7.72994 6.9085 6.94644 7.875 6.94644C8.8415 6.94644 9.625 7.72994 9.625 8.69644V48.9464C9.625 49.9129 8.8415 50.6964 7.875 50.6964C6.9085 50.6964 6.125 49.9129 6.125 48.9464V8.69644Z" fill="#ED4652" />
          <path d="M26.9146 40.1757L27.2459 40.7088C27.5275 41.1619 27.9097 41.5441 28.3628 41.8257L28.8959 42.157C29.0642 42.2615 29.0642 42.5063 28.8959 42.6109L28.3628 42.9422C27.9097 43.2238 27.5275 43.6059 27.2459 44.0591L26.9146 44.5922C26.8101 44.7604 26.5653 44.7604 26.4607 44.5922L26.1294 44.0591C25.8478 43.6059 25.4657 43.2238 25.0125 42.9422L24.4794 42.6109C24.3112 42.5063 24.3112 42.2615 24.4794 42.157L25.0125 41.8257C25.4657 41.5441 25.8478 41.1619 26.1294 40.7088L26.4607 40.1757C26.5653 40.0074 26.8101 40.0074 26.9146 40.1757Z" fill="#EBC05E" />
          <path d="M28.8752 34.0714C28.8752 33.1049 29.6587 32.3214 30.6252 32.3214C35.4577 32.3214 39.3752 28.4039 39.3752 23.5714C39.3752 18.739 35.4577 14.8214 30.6252 14.8214H29.1252C25.2048 14.8214 22.0112 17.9331 21.8794 21.8214H26.3604C26.749 20.3119 28.1193 19.1964 29.7502 19.1964H30.6252C33.0414 19.1964 35.0002 21.1552 35.0002 23.5714C35.0002 25.9877 33.0414 27.9464 30.6252 27.9464H28.0002C26.0672 27.9464 24.5002 29.5134 24.5002 31.4464V34.0714C24.5002 34.5547 24.8919 34.9464 25.3752 34.9464H28.0002C28.4834 34.9464 28.8752 34.5547 28.8752 34.0714Z" fill="#EBC05E" />
        </svg>
      </div>
    </div>
  )
}
