import React from 'react'

export const PickWinner = ({ t, handleSubmitChoice,isDisable }) => {
    return (
        <div className='pb-5 mt-6 text-center'>
            <div className="flex items-center self-stretch justify-around gap-10 py-0 mb-8">
                <button style={{cursor: isDisable ? "not-allowed" : "pointer"}} disabled={isDisable} onClick={() => handleSubmitChoice("middle", "")} className="z-10 flex items-center self-stretch justify-center w-32 h-12 gap-2 p-2 text-sm font-medium leading-6 text-white rounded-lg md:text-2xl hover:shadow-3xl hover:shadow-secondary-900 md:w-40 md:h-14 bg-tertiary-900">
                        🙄 No winner
                </button>
            </div>
        </div>
    )
}
