import React, { useState, useEffect } from "react";
import fr from "../assets/images/fr.svg";
import en from "../assets/images/en.svg";
import de from "../assets/images/de.svg";
import CustomCheckBox from "./CustomCheckBox";
import { useMediaQuery } from "@uidotdev/usehooks";

const toUpperCase = (val) => {
  return val[0].toUpperCase() + val.slice(1);
};

export const CustomDropdown = ({ handleChange, categories, languages }) => {

  const isLargeDevice = useMediaQuery("only screen and (min-width : 769px)");
  const [blocVisible, setBlocVisible] = useState(false);
  const [langVisible, setLangVisible] = useState(false);

  const [checkedLang, setCheckedLang] = useState(["en"]);
  const [checkedCat, setCheckedCat] = useState([]);

  const handleCheckedCat = (val) => {
    const valIndex = checkedCat.indexOf(val);
    const categoriesId = categories.map(item => item.id)
    if (val === 'all') {
      if (checkedCat.length < categoriesId.length + 1) {
        setCheckedCat([...categoriesId, 'all'])
      } else {
        setCheckedCat([])
      }
    } else {
      if (valIndex !== -1) {
        const newVal = checkedCat.filter((v) => v !== val && v !== 'all')
        setCheckedCat([...newVal])
      } else {
        let newCheckedCat = checkedCat
        newCheckedCat.push(val)
        setCheckedCat([...newCheckedCat])
      }
    }
  }

  const handleCheckedLang = (val) => {
    const valIndex = checkedLang.indexOf(val);
    const langName = languages.map(item => item.name)
    if (val === 'all') {
      if (checkedLang.length < languages.length) {
        setCheckedLang([...langName, 'all'])
      } else {
        setCheckedLang([])
      }
    } else {
      if (valIndex !== -1) {
        const newVal = checkedLang.filter((v) => v !== val && v !== 'all')
        setCheckedLang([...newVal])
      } else {
        let newCheckedLang = checkedLang
        newCheckedLang.push(val)
        setCheckedLang([...newCheckedLang])
      }
    }
  }

  const handleClick = (type) => {
    if (type === "category") {
      setBlocVisible(!blocVisible);
    } else {
      setLangVisible(!langVisible);
    }
  };


  useEffect(() => {
    if (sessionStorage.getItem('arena_saved_categories')) {
      setCheckedCat([...(JSON.parse(sessionStorage.getItem('arena_saved_categories'))).map(item => item.id)])
    } else {
      setCheckedCat([...categories?.map(item => item.id)])
    }

    if (sessionStorage.getItem('arena_saved_languages')) {
      setCheckedLang([...(JSON.parse(sessionStorage.getItem('arena_saved_languages'))).map(item => item.name)])
    } 


    return () => {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])


  useEffect(() => {
    if (checkedLang.length === languages.length && languages.length !== 0) {
      let newCheckedLang = checkedLang
      newCheckedLang.push("all")
      setCheckedLang([...newCheckedLang])
    }

    if (checkedCat.length === categories.length && checkedCat.length !== 0) {
      console.info("here ", categories)
      let newCheckedCat = checkedCat;
      newCheckedCat.push("all");
      setCheckedCat([...newCheckedCat])
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedLang, checkedCat, categories])




  return (
    <form className={isLargeDevice ? "relative flex gap-1 p-1 mx-auto w-[80%] md:p-4 justify-evenly mb-5" : "relative flex gap-3 p-1 mx-auto w-full md:p-4 justify-evenly"}>
      <div
        className="absolute left-0 inline-flex flex-col w-3/5 gap-2"
      >
        <div
          onClick={() => handleClick("category")}
          className="flex flex-col items-start self-stretch h-11 rounded-lg border-1 border-[#3a1e0e] bg-[#f9edcc] gap-2 -z-2 md:w-2/3 md:mx-auto">
          <input
            aria-disabled
            placeholder="Categories"
            className="hover:shadow-3xl hover:shadow-primary hover:bg-secondary pl-2 bg-[#f9edcc] focus:outline-none placeholder-font-dmMonoLight placeholder-leading-5 placeholder-[#3a1e0e] flex-shrink-0 w-full h-11 rounded-lg border border-[#3a1e0e] focus:border-[#3a1e0e] hover::border-[#3a1e0e] cursor-pointer"
          />
        </div>

        {blocVisible && (
          <div className="flex flex-col items-start self-stretch  rounded-lg border-1 border-[#3a1e0e] bg-[#f9edcc] gap-2 md:w-2/3 md:mx-auto">
            <div className="w-full max-h-72  flex -mt-1 border-2 border-primary flex-col items-start gap-0.5 self-stretch p-1 rounded-lg bg-[#f9edcc] md:mx-auto  pl-2 flex-shrink-0 z-30">

              <div className="relative overflow-y-scroll overflow-x-hidden w-full max-h-60  pt-2 flex -mt-1  flex-col items-start gap-0.5 self-stretch p-1 rounded-lg  md:mx-auto  pl-2 flex-shrink-0 z-30">

                <div
                  className="mt-1 flex justify-start items-start gap-2 self-stretch pt-[0.5625rem] pb-[0.5625rem] pl-4 pr-5 h-9 rounded-lg bg-[#f9edcc]  hover:bg-[#fdf9ed] Mono'] text-[#3a1e0e] text-sm leading-5"
                  key={"all"}
                >
                  {" "}
                  <CustomCheckBox handleState={handleCheckedCat} currentVals={checkedCat} id="all" />
                  <label htmlFor="all">ALL</label>
                </div>

                {categories.map((element) => (
                  <div className="flex items-center gap-2 self-stretch pt-[0.5625rem] pb-[0.5625rem] pl-4 pr-5 h-9 rounded-lg bg-[#f9edcc] font-dmMonoLight hover:bg-[#fdf9ed] text-[#3a1e0e] text-sm leading-5"
                    key={element.id}>
                    <CustomCheckBox handleState={handleCheckedCat} currentVals={checkedCat} id={element.id} />

                    <label htmlFor={element.id}>{toUpperCase(element.name)}</label>
                  </div>
                ))}


              </div>
              <div className="bottom-0  w-full flex justify-center items-center gap-2 self-stretch pt-[2px] pb-[0.5625rem] pl-4 pr-5 h-9 rounded-lg bg-[#f9edcc] text-[#939393] font-dmMonoMedium text-sm leading-3">
                <button onClick={() => {
                  handleClick("category")
                  handleChange([...checkedCat], 'category');
                }} className="z-10 flex items-center justify-center w-10 gap-2 p-2 mt-2 rounded-lg hover:shadow-3xl hover:shadow-secondary-900 bg-tertiary-900">
                  <div className="text-sm font-medium leading-4 text-white font-dmMonoMedium">OK</div>

                </button>
              </div>

            </div>
          </div>

        )}
      </div>

      <div

        className="absolute right-0 inline-flex flex-col w-2/5 gap-2 pl-2 md:w-1/5 font-dmMonoLight"
      >
        <div onClick={() => handleClick("lang")} className="flex flex-col items-start gap-1 mr-4 md:gap-2 -z-2">
          <div className="self-stretch rounded-lg cursor-pointer h-11 border-1 border-primary bg-secondary">
            <div
              aria-disabled
              className="flex justify-center items-center bg-[#f9edcc] hover:shadow-3xl hover:shadow-primary  placeholder-leading-5  flex-shrink-0 h-11 rounded-lg border border-[#3a1e0e] focus:border-[#3a1e0e] hover::border-[#3a1e0e]  hover:bg-secondary"
            >
              Languages
            </div>
          </div>
        </div>
        {langVisible && (
          <div className="pt-1 pb-1 mr-3 border-2 rounded-lg border-primary bg-secondary z-2">
            <div className=" relative overflow-y-scroll  flex   flex-col items-center gap-0.5 self-stretch  ">

              <div
                className=" flex items-center gap-2 self-stretch pt-[0.5625rem] pb-[0.5625rem] pl-3 pr-3 h-9 rounded-lg bg-[#f9edcc] Mono'] hover:bg-[#fdf9ed] Mono'] text-[#3a1e0e] font-['DM text-sm leading-5"
                key={"all"}
              >
                {" "}
                <CustomCheckBox handleState={handleCheckedLang} currentVals={checkedLang} id="all" />
                <label htmlFor="all">ALL</label>
              </div>

              {languages.map((lang) => (
                <div
                  className="flex items-center gap-2 self-stretch pt-[0.5625rem] pb-[0.5625rem] pl-3 pr-3 h-9 rounded-lg bg-[#f9edcc] Mono'] hover:bg-[#fdf9ed] Mono'] text-[#3a1e0e] font-['DM text-sm leading-5"
                  key={lang.name}
                >
                  {" "}
                  <CustomCheckBox handleState={handleCheckedLang} currentVals={checkedLang} id={lang.name} />
                  {lang.name === 'en' && (<img src={en} alt="english" className="w-5 h-5 rounded-3xl" />)}
                  {lang.name === 'fr' && (<img src={fr} alt="français" className="w-5 h-5 rounded-3xl" />)}
                  {lang.name === 'de' && (<img src={de} alt="allemand" className="w-5 h-5 rounded-3xl" />)}
                  <label htmlFor={lang.name}>{toUpperCase(lang.name)}</label>
                </div>
              ))}

            </div>
            <div className="mt-1  w-full flex justify-center items-center gap-2 self-stretch pt-[2px] pb-[0.5625rem] pl-4 pr-5 h-9 rounded-lg bg-[#f9edcc]  text-[#939393] text-sm leading-3 font-dmMonoMedium">


              <button onClick={() => {
                handleClick("lang")
                handleChange([...checkedLang], 'lang');
              }} className="z-10 flex items-center justify-center w-10 gap-2 p-2 mt-2 rounded-lg hover:shadow-3xl hover:shadow-secondary-900 bg-tertiary-900">
                <div className="text-sm font-medium leading-4 text-white font-dmMonoMedium">OK</div>

              </button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
