import React from "react";
import warriorOne from '../assets/images/warrior_one.svg'
import warriorTwo from '../assets/images/warrior_two.svg'
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();
  const handleMenuClick = (link) => {
    navigate(link)
  }
  return (
    <div className=''>

      <div className="flex flex-col items-center gap-6 self-stretch pt-[3.75rem] pb-6 px-0">
        <div className=" text-primary font-game text-[2rem] md:text-[3.25rem] leading-[48px]">Welcome to LLM ARENA</div>
        <div className=" font-dmMonoLight flex flex-col justify-center items-center gap-2.5 self-stretch px-4 p-0  text-primary text-center text-sm md:text-xl leading-10">
          Welcome to the LLM Arena! Help us build an open-source dataset for fine-tuning large language models with RLHF. Chose the best generations knowing that collected data will be released to the community!
        </div>
      </div>
      <div className="flex self-stretch justify-between w-full gap-1 ">
        <div className="flex w-full  justify-between items-center mx-auto flex-nowrap self-stretch pt-[4.625rem]">
          <img src={warriorOne} alt="warrior one" className="w-20 h-20 md:w-40 md:h-40 lg:w-60 lg:h-60" />
          <button onClick={() => handleMenuClick('/play')} className="z-10 flex items-center justify-center w-24 gap-2 p-2 rounded-lg hover:shadow-3xl hover:shadow-secondary-900 sm:h-10 md:w-32 md:h-14 lg:w-40 bg-tertiary-900">
            <div className="text-xl font-medium leading-6 text-white font-dmMonoMedium">Play</div>
            <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.91058 17.0655C6.58514 16.74 6.58514 16.2124 6.91058 15.887L12.1547 10.6429L6.91058 5.39881C6.58514 5.07337 6.58514 4.54574 6.91058 4.2203C7.23602 3.89486 7.76366 3.89486 8.08909 4.2203L13.9224 10.0536C14.2479 10.3791 14.2479 10.9067 13.9224 11.2321L8.08909 17.0655C7.76366 17.3909 7.23602 17.3909 6.91058 17.0655Z" fill="white" />
            </svg>
          </button>
          <img src={warriorTwo} alt="warrior two" className="w-20 h-20 md:w-40 md:h-40 lg:w-60 lg:h-60" />
        </div>
      </div>
    </div>
  );
};
