import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from '../components/Loader';
import { CustomDropdown } from '../components/CustomDropdown';
import { QuestionCard } from '../components/QuestionCard';
import { PlayerChoice } from '../components/PlayerChoice';
import { PickWinner } from '../components/PickWinner';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Stepper } from '../components/Stepper';
import links from '../constants/links';
import { Menu } from '../components/Menu';
import { useMatch } from 'react-router-dom';
import { useMediaQuery } from '@uidotdev/usehooks';


export const Game = () => {

    const match = useMatch('/play');


    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const isLargeDevice = useMediaQuery("only screen and (min-width : 769px)");


    // for prod env, use "PROD" and "DEV" for dev env
    const env = "PROD";

    const { t } = useTranslation();
    const [firstInit, setFirstInit] = useState(true);
    const [language, setLanguage] = useState(
        sessionStorage.getItem('arena_saved_languages') ? JSON.parse(sessionStorage.getItem('arena_saved_languages')).map(l => l.name) : ['en']
    );
    const [languages, setLanguages] = useState([]);
    const [level, setLevel] = useState(
        sessionStorage.getItem('arena_level') ?
            parseInt(sessionStorage.getItem('arena_level')) : 0);
    const [time, setTime] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(
        sessionStorage.getItem('arena_saved_categories') ? JSON.parse(sessionStorage.getItem('arena_saved_categories')).map(l => l.id) : ['all']
    );

    const navigate = useNavigate();

    const country = useRef(null);

    const [currentPrompt, setCurrentPrompt] = useState({
        uuid: "",
        generationOne: {},
        generationTwo: {},
        timeElapsed: 0
    })

    const [arenaData, setArenaData] = useState({})


    useEffect(() => {
        let controller = new AbortController();
        async function getCountry() {
            const response = await fetch(`https://api.country.is`)
            if (response.ok) {
                const data = await response.json();
                country.current = data.country;
            } else {
                console.log("Unable to get the country : ", response)
            }
        }

        getCountry();

        async function loadCategories() {
            const response = await fetch(links[env].loadCategories);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);

            } else {
                console.log("error: ", response)
            }
        }


        async function loadLanguages() {
            const response = await fetch(links[env].loadLanguages);
            if (response.ok) {
                const data = await response.json();
                setLanguages(data);

            } else {
                console.log("error: ", response)
            }
        }

        loadCategories();
        loadLanguages();
        return () => {
            controller.abort();
        };
    }, []);


    const handleChange = (value, type) => {
        if (type === "lang") {
            setLanguage(value);
            const tempLanguages = languages.filter(item => value.includes(item.name))
            sessionStorage.setItem("arena_saved_languages", JSON.stringify(tempLanguages))
        } else {
            setCategory(value)
            const tempCategories = categories.filter(item => value.includes(item.id))
            sessionStorage.setItem("arena_saved_categories", JSON.stringify(tempCategories))
        }
    };


    useEffect(() => {
        const timer = setInterval(() => {
            setTime(time => time + 1);
        }, 1)
        return () => clearInterval(timer);

    }, []);


    useEffect(() => {
        let controller = new AbortController();
        if (!localStorage.getItem('arena-uid')) { localStorage.setItem('arena-uid', uuidv4()) }

        async function loadData() {


            let urlPath = "";
            if (sessionStorage.getItem('session') != null && sessionStorage.getItem('remainingPrompts') != null) {
                const data = JSON.parse(sessionStorage.getItem('arena-data'))
                urlPath = `/${sessionStorage.getItem('session')}/${sessionStorage.getItem('remainingPrompts')}/${data.prompts?.length}`
            }
            const response = await fetch(links[env].loadData + localStorage.getItem('arena-uid') + urlPath, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    categories: category.indexOf('all') !== -1 ? ['all'] : category,
                    languages: language.indexOf('all') !== -1 ? ['all'] : language.sort()
                }),
            });
            if (response.ok) {
                const data = await response.json();
                setArenaData(data);
                sessionStorage.setItem("arena-data", JSON.stringify(data))
                if (data.prompts.length > 0) {
                    setCurrentPrompt({
                        uuid: data.prompts[level].uuid,
                        prompt: data.prompts[level].prompt,
                        generationOne: data.prompts[level].generations[0],
                        generationTwo: data.prompts[level].generations[1],
                        timeElapsed: 0
                    })
                    setIsLoading(false)
                    setFirstInit(false)

                }
            } else {
                setHasError(true);
                console.log(response)
            }
        }
        if (category.length !== 0) {

            if (firstInit) {
                if (sessionStorage.getItem('arena-data') != null) {
                    const data = JSON.parse(sessionStorage.getItem('arena-data'))
                    setArenaData(data);
                    if (data.prompts.length > 0) {
                        setCurrentPrompt({
                            uuid: data.prompts[level].uuid,
                            prompt: data.prompts[level].prompt,
                            generationOne: data.prompts[level].generations[0],
                            generationTwo: data.prompts[level].generations[1],
                            timeElapsed: 0
                        })
                        setIsLoading(false)
                        setFirstInit(false)

                    }
                } else {
                    loadData();
                }
            } else {
                loadData();
            }

        }
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, category]);

    useEffect(() => {
        if (level !== 0 && level < arenaData.prompts?.length) {
            setCurrentPrompt({
                ...currentPrompt,
                uuid: arenaData.prompts[level].uuid,
                prompt: arenaData.prompts[level].prompt,
                generationOne: arenaData.prompts[level].generations[0],
                generationTwo: arenaData.prompts[level].generations[1]
            })

        }

        if (arenaData.prompts?.length < parseInt(level) + 1) {
            sessionStorage.removeItem('remainingPrompts')
            navigate('/success')
        }


        return () => {

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [level]);

    async function postData(data) {


        const response = await fetch(links[env].postMatch, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            sessionStorage.setItem('session', data.session)
        } else {
            console.log(response);
            setHasError(true);
        }
    }

    const handleSubmitChoice = (choice, rating) => {

        const choices = {
            left: currentPrompt.generationOne.uuid,
            middle: "NoWinner",
            right: currentPrompt.generationTwo.uuid
        }

        const dataToSend = {
            promptId: currentPrompt.uuid,
            session: arenaData.session,
            winner: choices[choice],
            user_uuid: localStorage.getItem('arena-uid'),
            elapsed_time: time,
            country: country?.current,
            rating:rating
        }
        postData(dataToSend);

        setTime(0)
        setLevel((level) => (level + 1))
        sessionStorage.setItem('arena_level', parseInt(level) + 1);

        if (Object.keys(arenaData).length) {
            const remainingPrompts = arenaData.prompts?.length - parseInt(level) + 1
            sessionStorage.setItem('remainingPrompts', remainingPrompts)
        }

        document.getElementById('scrollToHere').scrollIntoView()

    }


    useEffect(() => {

        if (hasError) {
            toast.error(t('sorry'));
        }

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError])



    return (<div>
        <Toaster />
        {(isLargeDevice || (!match && isSmallDevice)) && <Menu handleChange={handleChange} languages={languages} categories={categories} />}
        <div className='w-full h-full mx-auto'>

            {isSmallDevice && <CustomDropdown handleChange={handleChange} languages={languages} categories={categories} />}
            {
                (isLoading ?
                    <Loader /> :
                    <>
                        <Stepper current={level} total={arenaData.prompts?.length} />
                        <br />
                        <QuestionCard question={currentPrompt.prompt} />
                        <PlayerChoice t={t} generationOne={currentPrompt.generationOne} generationTwo={currentPrompt.generationTwo} isDisable={(arenaData.prompts?.length < parseInt(level) + 1)} handleSubmitChoice={handleSubmitChoice} />
                        <PickWinner t={t} isDisable={(arenaData.prompts?.length < parseInt(level) + 1)} handleSubmitChoice={handleSubmitChoice} />
                    </>)
            }
        </div>
    </div>)
}

