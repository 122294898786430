import React from 'react'
import { useMatch } from 'react-router-dom'
import { Menu } from '../components/Menu';
import { Footer } from '../components/Footer';
import { useMediaQuery } from '@uidotdev/usehooks';

export default function Main({ children }) {

  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isLargeDevice = useMediaQuery("only screen and (min-width : 769px)");



  const match = useMatch('/play');


  return (
    <div className='relative flex flex-col justify-between h-full' >
      <div className='w-full h-full px-2 pt-10 md:px-12 lg:px-16'>
        {((!match && isLargeDevice) || isSmallDevice) && <Menu />}
        {children}
      </div>
      <Footer />
    </div>
  )
}
